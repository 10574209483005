import DashboardLayout from "../../components/DashboardLayout";
import Tableau from "./Tableau";

export default function Dashboard(){
    if(!window.localStorage.token){
        window.location.href = "/auth/login"
    }

    return <div>
        <DashboardLayout>
            <Tableau/>
        </DashboardLayout>
    </div>
}