import { BrowserRouter,  Routes, Route} from "react-router-dom";
import Dashboard from "./pages/dashboard/Dashboard";
import Login from "./pages/auth/Login";

export default function MainRouter(){
    return (
        <div>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Dashboard/>}/> 
                    <Route path="/auth/login" element={<Login/>}/>
                </Routes>
            </BrowserRouter>
        </div>
    );
    }