import { useEffect, useState } from "react"
import { _check_subscriber_status, _get_data_usage, _update_subscriber } from "../../utils/api"
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';

import './tableauStyle.css';

const people = [
    { name: 'Lindsay Walton', title: 'Front-end Developer', email: 'lindsay.walton@example.com', role: 'Member' },
    // More people...
]

const GB_TO_KB = 1048576;
const MAX_USAGE_PER_MONTH = parseFloat(process.env.REACT_APP_MAX_USAGE_PER_MONTH || 314572800);

export default function Tableau() {
    const [loading_usage_data, set_loading_usage_data] = useState(true);
    const [usage_data, set_usage_data] = useState([]);

    // end date should be the 17th of this month and start date should be the 18th of the previous month if the day is less than 18
    const default_start_date = new Date();
    default_start_date.setDate(18);
    if (default_start_date.getDate() < 18) {
        default_start_date.setMonth(default_start_date.getMonth() - 1);
    }

    const default_end_date = new Date();
    default_end_date.setDate(17);
    if (default_end_date.getDate() < 18) {
        default_end_date.setMonth(default_end_date.getMonth() - 1);
    }else{
        default_end_date.setMonth(default_end_date.getMonth() + 1);
    }


    const [form_usage_data, set_form_usage_data] = useState({
        start_date: default_start_date,
        end_date: default_end_date,
    });

    const set_date_in_format = (date) => {
        return date.toISOString();
    }

    const get_data_usage = async (start_date, end_date) => {
        try {
            set_loading_usage_data(true);
            const _start_date = start_date || new Date().toISOString();
            const _end_date = end_date || new Date().toISOString();
            const usage_data = await _get_data_usage(_start_date, _end_date);
            set_loading_usage_data(false);
            console.log(usage_data);
            set_usage_data(usage_data);
        } catch (e) {
            set_loading_usage_data(false);
            console.log(e);
            // check if error contains "Unauthorized"
            if (e.message.includes("Unauthorized")) {
                alert("You are not authorized to view this page. Please login to view this page.");
                window.location.href = "/auth/login";
            } else {
                alert("Was not able to get data usage");
            }
        }
    }

    const handle_submit_get_usage_data = async (e) => {
        e.preventDefault();
        get_data_usage(form_usage_data.start_date, form_usage_data.end_date);
    }

    const [show_subscriber_status, set_show_subscriber_status] = useState({
        index: -1,
        status: ""
    });

    const [getting_subscriber_info, set_getting_subscriber_info] = useState(-1);
    const handle_check_subscriber_status = async (subscriber_id, index) => {
        // alert("Index is " + index);
        try {
            set_getting_subscriber_info(index);
            let subscriber_info = await _check_subscriber_status(subscriber_id);
            set_getting_subscriber_info(-1);

            set_show_subscriber_status({
                index: index,
                status: subscriber_info.status
            });

        } catch (err) {
            set_getting_subscriber_info(-1);
            alert("Was not able to get subscriber info");
        }
    }

    const [suspending_subscriber, set_suspending_subscriber] = useState(-1);
    const handle_suspend_subscriber = async (subscriber_id, index) => {
        try {
            set_suspending_subscriber(index);
            let subscriber_info = await _update_subscriber(subscriber_id, "a", "s");
            set_suspending_subscriber(-1);
            alert("Subscriber suspended successfully");
        } catch (err) {
            set_suspending_subscriber(-1);
            alert("Was not able to suspend subscriber");
        }
    }

    const [globalFilter, setGlobalFilter] = useState(null);

    const [unsuspending_subscriber, set_unsuspending_subscriber] = useState(-1);
    const handle_unsuspend_subscriber = async (subscriber_id, index) => {
        try {
            set_unsuspending_subscriber(index);
            let subscriber_info = await _update_subscriber(subscriber_id, "a", "r");
            set_unsuspending_subscriber(-1);
            alert("Subscriber restored successfully");
        } catch (err) {
            set_unsuspending_subscriber(-1);
            alert("Was not able to unsuspend subscriber");
        }
    }

    useEffect(() => {
        get_data_usage(set_date_in_format(default_start_date), set_date_in_format(default_end_date));
    }, []);

    return (
        <div className="px-4 sm:px-6 lg:px-8">
            <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                    <h1 className="text-base font-semibold leading-6 text-gray-900">Data usage</h1>
                    <p className="mt-2 text-sm text-gray-700">
                        Here is the data usage for the last 30 days. You can add new users, edit their information, and more.
                    </p>
                </div>

                <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">

                </div>
            </div>
            <div className="my-4">
                <form onSubmit={handle_submit_get_usage_data}>
                    <div className="flex">
                        <div className="flex">
                            <label className="my-2 text-sm">Start date</label>
                            <input type="date" onChange={(e) => {
                                set_form_usage_data({
                                    ...form_usage_data,
                                    start_date: set_date_in_format(new Date(e.target.value))
                                });

                            }}
                                className="rounded-md border-gray-300 text-xs mx-2" />
                        </div>
                        <div className="flex mx-2">
                            <label className="my-2 text-sm">End date</label>
                            <input type="date" onChange={(e) => {
                                set_form_usage_data({
                                    ...form_usage_data,
                                    end_date: set_date_in_format(new Date(e.target.value))
                                });
                            }}
                                className="rounded-md border-gray-300 text-xs mx-2" />
                        </div>
                        <div className="flex mx-2">
                            <button type="submit" className="rounded-md bg-indigo-600 text-white px-3 py-1.5 text-xs font-semibold leading-6 hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                                Get data usage
                            </button>
                        </div>
                    </div>
                </form>
            </div>
            <div>
                Getting data usage for the period <b>{new Date(form_usage_data.start_date).toLocaleDateString()}</b> to <b>{new Date(form_usage_data.end_date).toLocaleDateString()}</b>.
            </div>

            <div className="mt-8 flow-root">
            <div className="global-filter-container">
                    <InputText 
                        className="rounded-md border-gray-300 my-2"
                        value={globalFilter} 
                        onChange={(e) => setGlobalFilter(e.target.value)} 
                        placeholder="Filter subscribers..." 
                    />
                </div>
                <DataTable value={usage_data}  globalFilter={globalFilter} sortField="total_usage" sortOrder={-1} tableStyle={{ minWidth: '30rem', paddingBottom : '10px'}}>
                    <Column field="_id.subscriber" header="Subscriber" sortable style={{ width: '20%' }}></Column>
                    <Column field="total_usage" header="Total usage" body={TotalUsageTemplate} sortable style={{ width: '20%' }}></Column>
                    <Column field="total_usage" header="Health" body={HealthUsageTemplate} sortable style={{ width: '20%' }}></Column>
                    <Column field="quantity" header="Action" body={ActionTemplate} style={{ width: '20%' }}></Column>
                </DataTable>
            </div>
            
        </div>
    )
}

const TotalUsageTemplate = (data) => {
    return <div>{parseFloat(parseFloat(data.total_usage) / GB_TO_KB).toFixed(2)} GB</div>;
}

const HealthUsageTemplate = (data) => {
    return <div>
        {parseFloat(data.total_usage) >= 0 && parseFloat(data.total_usage) < MAX_USAGE_PER_MONTH * 0.7 && <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
            <div className="inline-block w-3 h-3 bg-green-500 rounded-full relative top-1 mx-2"></div>
            Good
        </span>}
        {parseFloat(data.total_usage) >= MAX_USAGE_PER_MONTH * 0.7 && parseFloat(data.total_usage) < MAX_USAGE_PER_MONTH * 0.9 && <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-800">
            <div className="inline-block w-3 h-3 bg-yellow-500 rounded-full relative top-1 mx-2"></div>
            Warning
        </span>}
        {parseFloat(data.total_usage) >= MAX_USAGE_PER_MONTH * 0.9 && parseFloat(data.total_usage) < MAX_USAGE_PER_MONTH * 1 && <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
            <div className="inline-block w-3 h-3 bg-red-500 rounded-full relative top-1 mx-2"></div>
            Critical
        </span>}
        {parseFloat(data.total_usage) > MAX_USAGE_PER_MONTH * 1 && <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
            <div className="inline-block w-3 h-3 bg-red-500 rounded-full relative top-1 mx-2"></div>
            Exhausted
        </span>}

    </div>
}

const ActionTemplate = (data) => {
    const [show_subscriber_status, set_show_subscriber_status] = useState({
        status: "",
        show : false
    });

    const [getting_subscriber_info, set_getting_subscriber_info] = useState(false);
    const handle_check_subscriber_status = async (subscriber_id) => {
        // alert("Index is " + index);
        try {
            set_getting_subscriber_info(true);
            let subscriber_info = await _check_subscriber_status(subscriber_id);
            set_getting_subscriber_info(false);

            set_show_subscriber_status({
                status: subscriber_info.status,
                show : true
            });

        } catch (err) {
            set_getting_subscriber_info(false);
            alert("Was not able to get subscriber info");
        }
    }

    const [suspending_subscriber, set_suspending_subscriber] = useState(false);
    const handle_suspend_subscriber = async (subscriber_id, index) => {
        try {
            set_suspending_subscriber(index);
            let subscriber_info = await _update_subscriber(subscriber_id, "a", "s");
            set_suspending_subscriber(false);
            alert("Subscriber suspended successfully");
        } catch (err) {
            set_suspending_subscriber(false);
            alert("Was not able to suspend subscriber");
        }
    }

    const [unsuspending_subscriber, set_unsuspending_subscriber] = useState(false);
    const handle_unsuspend_subscriber = async (subscriber_id) => {
        try {
            set_unsuspending_subscriber(true);
            let subscriber_info = await _update_subscriber(subscriber_id, "a", "r");
            set_unsuspending_subscriber(false);
            alert("Subscriber restored successfully");
        } catch (err) {
            set_unsuspending_subscriber(false);
            alert("Was not able to unsuspend subscriber");
        }
    }

    return <div style={{paddingBottom: '10px', paddingTop : '10px'}}>
        {/* Suspend, Unsuspend, Check status buttons */}
        {/* <span className="isolate inline-flex rounded-md shadow-sm"> */}

        {show_subscriber_status && show_subscriber_status.show ? <div className="my-1"> {show_subscriber_status.status === "Active" ? <span className="font-bold text-green-700">Subscriber is {show_subscriber_status.status}</span> : <span className="font-bold text-red-700">Subscriber is {show_subscriber_status.status}</span>}</div> : <></>}

        <button type="button" onClick={() => handle_check_subscriber_status(data._id.subscriber)} className="relative block rounded-l-md bg-white px-3 py-2 text-xs font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10">
            {getting_subscriber_info ? 'Checking...' : 'Check status'}
        </button>
        <button type="button" onClick={() => handle_unsuspend_subscriber(data._id.subscriber)} className="relative block rounded-l-md bg-white px-3 py-2 text-xs font-semibold text-blue-900 ring-1 ring-inset ring-blue-300 hover:bg-blue-50 focus:z-10">
            {unsuspending_subscriber ? 'Restoring...' : 'Restore'}
        </button>
        <button type="button" onClick={() => handle_suspend_subscriber(data._id.subscriber)} className="relative block rounded-l-md bg-white px-3 py-2 text-xs font-semibold text-red-900 ring-1 ring-inset ring-red-300 hover:bg-red-50 focus:z-10">
            {suspending_subscriber ? 'Suspending...' : 'Suspend'}
        </button>
        {/* </span> */}
    </div>
}