import {useState} from "react";
import { _login } from "../../utils/api";

export default function Login() {
    const [form_obj, set_form_obj] = useState({
      username : "",
      password : ""
    });

    const [logging_in, set_logging_in] = useState(false);
    const handle_submit_login = async (e) => {
      e.preventDefault();
      try{  
        set_logging_in(true);
        if(!form_obj.username){
          alert("Please, enter the username");
          return;
        }
  
        if(!form_obj.password){
          alert("Please, enter the password");
          return;
        }
  
        const login_res = await _login(form_obj);
        set_logging_in(false);
        if(login_res && login_res.token){
          localStorage.token = login_res.token;
          window.location.href = "/";
        }

      }catch(e){
        set_logging_in(false);
        alert("Failed to log in. Please, check your username or password.");
      }
    }

    return (
      <>
        <div className="flex min-h-full flex-1 items-center justify-center px-4 py-12 sm:px-6 lg:px-8">
          <div className="w-full max-w-sm space-y-10">
            <div>
              <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                Sign in to your account
              </h2>
            </div>
            <form onSubmit={handle_submit_login} className="space-y-6" method="POST">
              <div className="relative -space-y-px rounded-md shadow-sm">
                <div className="pointer-events-none absolute inset-0 z-10 rounded-md ring-1 ring-inset ring-gray-300" />
                <div>
                  <label htmlFor="email-address" className="sr-only">
                    Username 
                  </label>
                  <input
                    id="email-address"
                    name="username"
                    onChange={e => set_form_obj({...form_obj, 'username' : e.target.value})}
                    type="text"
                    required
                    className="relative block w-full rounded-t-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-100 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    placeholder="Username"
                  />
                </div>
                <div>
                  <label htmlFor="passphrase" className="sr-only">
                    Passphrase
                  </label>
                  <input
                    id="password"
                    name="password"
                    type="password"
                    onChange={e => set_form_obj({...form_obj, 'password' : e.target.value})}
                    autoComplete="current-password"
                    required
                    className="relative block w-full rounded-b-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-100 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    placeholder="Passphrase"
                  />
                </div>
              </div>
  
  
              <div>
                <button
                  type="submit"
                  className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  {logging_in ? 'Signing in ...' : 'Sign in'}
                </button>
              </div>
            </form>            
          </div>
        </div>
      </>
    )
  }
  