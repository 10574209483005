// import i18next from "../i18next";

// const get_language = () => {    ;
//     return i18next.language;
// }

const xhr_request = async (end_point, type = "GET", auth = false, payload) => {
    let options = {
        method: type,
        headers: {}
    };

    options.headers['Content-Type'] = 'application/json';
    // options.headers['Accept-Language'] = get_language();

    if (payload) {
        options.body = JSON.stringify(payload);
    }

    if (auth === true) {
        options.headers['Authorization'] = `Bearer ${localStorage.token}`;
    }

    try {
        const res = await fetch(`${process.env.REACT_APP_BACK_END_API}/${end_point}`, options);
        // check if status code is between 200 and 299
        if (!res.ok) {
            throw new Error(res.statusText);
        }
        const data = await res.json();
        return data;
    } catch (err) {
        throw new Error(err);
    }
}

export const _get_data_usage = async (start_date, end_date) => {
    return await xhr_request(`data/aggregated/${start_date}/${end_date}`, 'GET', true);
}

export const _check_subscriber_status = async (subscriber_id) => {
    return await xhr_request(`subscriber/${subscriber_id}`, 'GET', true);
}

export const _update_subscriber = async (subscriber_id, action, value) => {
    return await xhr_request(`subscriber/${subscriber_id}/edit?${action}=${value}`, 'PUT', true);
}

export const _login = async (payload) => {
    console.log(payload);
    return await xhr_request("auth/login", "POST", true, payload);
}